import React from "react";
import { FC, useEffect, useState } from "react";
import Loader from "./Loader/Loader";
import companyData from '../assets/CompanyDetails.json'
import { Label, Stack } from "@fluentui/react";
interface IuserDetails {
  orgId: string;
  orgName: string;
  noOfUsers: number;
  price: string;
  product: string;
  tableInfo: string;
}
var userDetails: IuserDetails;

interface IOrderSummaryProps {
  paymentsProps: IuserDetails
}
const Payment: FC<any> = ({ paymentsProps }) => {
  const [loading, setLoading] = useState(true)
  //userDetails = paymentsProps
  console.log(userDetails)
  useEffect(() => {
    openOrder()
  }, [])

  // open order form  
  const openOrder = async () => {
    let functionName = "openOrder"
    try {
      let data = encodeURIComponent(JSON.stringify(paymentsProps));
      const clientId = companyData.clientId
      const clientSecret = companyData.clientSecret
      const tokenUrl = companyData.tokenUrl
      let createOrderUrl = companyData.createOrderUrl


      const headers = {
        'Authorization': 'Basic ' + btoa(clientId + ':' + clientSecret),
        'Content-Type': 'application/x-www-form-urlencoded'
      };

      const body = new URLSearchParams();
      body.append('grant_type', 'client_credentials');


      // Obtain access token
      const tokenResponse = await fetch(tokenUrl, {
        method: 'POST',
        headers,
        body: body.toString()
      });

      if (tokenResponse.ok) {
        const tokenData = await tokenResponse.json();
        const accessToken = tokenData.access_token;
        const return_url = companyData.return_url

        // Create order payload
        const orderPayload = {
          intent: 'CAPTURE',
          application_context: {
            "return_url": `${return_url}?userDetails=${data}`,
            "cancel_url": companyData.cancel_url,
            "brand_name": companyData.brand_name,
            "locale": "en-US",
            "user_action": "PAY_NOW",
            "payment_method": {
              "payee_preferred": "IMMEDIATE_PAYMENT_REQUIRED",
              "standard_entry_class_code": "TEL"
            },
          },
          purchase_units: [
            {
              reference_id: 'default',
              amount: {
                currency_code: 'USD',
                value: paymentsProps.productDetails.price
              },
            }
          ]
        };

        // Create order using access token
        await fetch(createOrderUrl, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(orderPayload)
        }).then((createOrderResponse: any) => {
          if (createOrderResponse.ok) {
            createOrderResponse.json().then((orderData: any) => {

              const selfLink = orderData.links.find((link: any) => link.rel === 'approve');
              if (selfLink) {
                //open paypal url
                window.location.href = selfLink.href;
                setLoading(false)
              }
            })
          } else {
            console.error('Error getting order link:', createOrderResponse.statusText);
          }
        })

      } else {
        console.error('Error obtaining access token:', tokenResponse.statusText);
        alert(functionName + tokenResponse.statusText)
      }
    } catch (error) {
      console.error('Error:', error);
      alert(functionName + ":" + error)
    }
  }

  return (<div>
    {loading ? (<><Stack>
      <Label style={{ fontWeight: "400", textAlign: "center", paddingTop: "10%" }}>Your payment request is being processed....</Label>
    </Stack></>) : (<>
      <Stack className='form'>
        <Stack.Item className='formDetails' align='center'>
        </Stack.Item >
      </Stack>
    </>)}
    <div id="paypal-button-container"></div>
  </div>)
}
export default Payment



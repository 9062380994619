import React from 'react';
import './App.css';
import StepIndicator from './Components/StepIndicator'
import { Stack } from '@fluentui/react';
import { initializeIcons } from '@fluentui/react';
import {BrowserRouter as Router} from 'react-router-dom';

initializeIcons()

function App() {
  return (
    <div className="App">
      <Router>
      <Stack>
        <StepIndicator></StepIndicator>
      </Stack>
      </Router>
      {/* < button onClick={updateState}>Click {selectedStatusIndex}</button> */}
    </div>
  );
}


export default App;

import React, { useState } from 'react';
import ConfirmSubscription from './ConfirmSubscription'
import Ordersummary from './OrderSummary';
import {
  Stack, Breadcrumb, IBreadcrumbItem, Label, IRenderFunction, IStackItemStyles, IStackTokens
} from '@fluentui/react';
import InogicLogo from '../Images/inogic-logo.png'
import Payment from './Payment';
import Activate from './Activation';
import { useLocation } from 'react-router-dom';
import Click2Undo from '../Images/click2undo.png';
import MapMyRelationship from '../Images/mapmyrelationship.png'
import Maplytics from '../Images/maplytics.png'
import Inolink from '../Images/inolink.png';
import Attach2Dynamics from '../Images/attach2dynamics.png';
import Click2Clone from '../Images/click2clone.png';
import KanbanBoard from '../Images/kanbanboard.png';
import Click2Export from '../Images/click2export.png';
import LeadAssignment from '../Images/LADA.png';
import CompanyInfo from '../assets/CompanyDetails.json';
import Marketing4Dynamics from '../Images/marketing4dynamics.png';
import Gamifics from '../Images/gamifics365.png';
import Whatsapp4Dynamics from '../Images/whatsapp4dynamics.png';
import BusinessProcessChecklist from '../Images/businessprocesschecklist.png';
import SharepointSecuritySync from '../Images/SharepointSecuritySync.png';
import BillingInfo from './BillingInfo';

const inogiclogoStyle: IStackItemStyles = {
  root: {
    alignItems: 'start',
    display: 'flex',
    height: 50,
    justifyContent: 'start',
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 5,
  padding: '2em',
};

/**
 * use to get product Dislay name and product image
 * @param queryParams 
 * @returns 
 */
const getProductDetails = (queryParams: any): any => {
  const functionName = "getProductName";
  let product: any = {
    productPath: "",
    productDisplayName: "",
    productEula: "",
    activateLicUrl: "",
  };
  let productName: string = "";
  try {
    if (queryParams !== null) {

      if (queryParams.get('value') !== null) {
        let paramValue = queryParams.get('value')

        //@ts-ignore
        let orgDetails = JSON.parse(InoEncryptDecrypt.EncryptDecrypt.decryptKey(paramValue).DecryptedValue)
        productName = orgDetails.product.toLowerCase()
      }
      else if (queryParams.get('userDetails') !== null) {
        let paramValue = queryParams.get('userDetails')
        let priceDetails = JSON.parse(paramValue)
        productName = priceDetails.productDetails.product.toLocaleLowerCase()
      }

      switch (productName) {
        case "click2undo":
          product.productPath = Click2Undo;
          product.productDisplayName = "Undo2Restore";
          product.productEula = CompanyInfo.U2R;
          product.activateLicUrl = CompanyInfo.licActivate_Undo2Restore
          break;

        case "mapmyrelationship":
          product.productPath = MapMyRelationship;
          product.productDisplayName = "Map My Relationship";
          product.productEula = CompanyInfo.MMR;
          product.activateLicUrl = CompanyInfo.licActivate_MapMyRelationships
          break;

        case "kanbanboard":
          product.productPath = KanbanBoard;
          product.productDisplayName = "Kanban Board";
          product.productEula = CompanyInfo.KB;
          product.activateLicUrl = CompanyInfo.licActivate_KanbanBoard
          break;

        case "maplytics":
          product.productPath = Maplytics;
          product.productDisplayName = "Maplytics";
          product.productEula = CompanyInfo.Maplytics;
          product.activateLicUrl = CompanyInfo.licActivate_Maplytics
          break;

        case "click2export":
          product.productPath = Click2Export;
          product.productDisplayName = "Click2Export";
          product.productEula = CompanyInfo.C2E;
          product.activateLicUrl = CompanyInfo.licActivate_Click2Export
          break;

        case "inolink":
          product.productPath = Inolink;
          product.productDisplayName = "Inolink";
          product.productEula = CompanyInfo.InoLink;
          product.activateLicUrl = CompanyInfo.licActivate_InoLink
          break;

        case "click2clone":
          product.productPath = Click2Clone;
          product.productDisplayName = "Click2Clone";
          product.productEula = CompanyInfo.C2C;
          product.activateLicUrl = CompanyInfo.licActivate_Click2Clone
          break;

        case "leadassignment":
          product.productPath = LeadAssignment;
          product.productDisplayName = "Lead Assignment and Distribution Automation";

          break;

        case "attach2dynamics":
          product.productPath = Attach2Dynamics;
          product.productDisplayName = "Attach2Dynamics";
          product.productEula = CompanyInfo.A2D;
          product.activateLicUrl = CompanyInfo.licActivate_Attach2Dynamics
          break;

        case "marketing4dynamics.mailchimp":
          product.productPath = Marketing4Dynamics;
          product.productDisplayName = "Marketing4Dynamics.Mailchimp";
          product.productEula = CompanyInfo.M4D;
          product.activateLicUrl = CompanyInfo.licActivate_Marketing4Dynamics
          break;

        case "gamifics365":
          product.productPath = Gamifics;
          product.productDisplayName = "Gamifics365";
          product.productEula = CompanyInfo.Gamifics;
          product.activateLicUrl = CompanyInfo.licActivate_Gamifics365;
          break;

        case "whatsapp4dynamics":
          product.productPath = Whatsapp4Dynamics;
          product.productDisplayName = "WhatsApp4Dynamics";
          product.productEula = CompanyInfo.W4D;
          product.activateLicUrl = CompanyInfo.licActivate_WhatsApp4Dynamics;
          break;

        case "businessprocesschecklist":
          product.productPath = BusinessProcessChecklist;
          product.productDisplayName = "Business Process Checklist";
          product.productEula = CompanyInfo.BPC;
          product.activateLicUrl = CompanyInfo.licActivate_Bpc;
          break;

        case "sharepointsecuritysync":
          product.productPath = SharepointSecuritySync;
          product.productDisplayName = "SharePoint Security Sync";
          product.productEula = CompanyInfo.SSS;
          product.activateLicUrl = CompanyInfo.licActivate_SharePointSecuritySync;
          break;
      }
    }
  }
  catch (error) {
    console.error(functionName + " : " + error)
  }
  return product
}

interface IuserDetails {
  orgId: string;
  orgName: string;
  noOfUsers: number;
  price: string;
  product: string;
  tableInfo: string;
}

//steps data
const itemsWithHeading: IBreadcrumbItem[] = [
  { text: 'Review Subscription', key: '1' },
  { text: 'Order Summary', key: '2' },
  { text: 'Billing & Shipping Info', key: '3' },
  { text: 'Payment', key: '4', },
  { text: 'Get Started', key: '5' },
];

//function component to dispay the steps 
const StepIndicator = () => {
  let step: number;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)

  const paramValue = queryParams.get('value');
  var productDetails = {
    productDisplayName: "",
    productPath: "",
    productEula: "",
    activateLicUrl: ""
  };

  const [userDetails, setUserDetaiils] = useState<IuserDetails>({
    orgId: "",
    orgName: "",
    noOfUsers: 0,
    price: "",
    product: "",
    tableInfo: "",
  })

  if (paramValue !== null) {
    productDetails = getProductDetails(queryParams)
    step = 0; // Set the index to 0 if the query value matches
  } else {
    productDetails = getProductDetails(queryParams)
    step = 4
    // Set the index to 3 for other cases
  }

  const [selectedStatusIndex, setSelectedStatusIndex] = useState(step);
  
  //read data from on confirm Subscription component to set the step
  const changeStep = () => {
    setSelectedStatusIndex(selectedStatusIndex + 1)
  };

  //Get user detail from OrderSummary
  const GetUserDetail = (userData: any) => {
    setUserDetaiils(userData)
    setSelectedStatusIndex(selectedStatusIndex + 1)

  }


  /**
   *
   * render ConfirmSubscription components to display the user details
   * @returns 
   */
  const DetailsForm = () => {
    return (
      <ConfirmSubscription onDataSend={changeStep} productDetails={productDetails}></ConfirmSubscription>
    )
  }
  const OrderSummary = () => {
    return (
      <div><Ordersummary onDataSend={GetUserDetail} productDetails={productDetails}></Ordersummary></div>
    )
  }
  const BillingInformation = () => {
    return (
      <div><BillingInfo onDataSend={GetUserDetail} productDetails={userDetails}></BillingInfo></div>
    )
  }
  const PaymentForm = () => {
    return (
      <div><Payment paymentsProps={userDetails}></Payment></div>
    )
  }
  const FourthStep = () => {
    return (
      <div><Activate productDetails={productDetails}></Activate></div>
    )
  }

  /**
   * Display content based on steps
   */
  const stepContentfunction = [
    DetailsForm,
    OrderSummary,
    BillingInformation,
    PaymentForm,
    FourthStep
  ]

  const StepContentComponent = stepContentfunction[selectedStatusIndex];

  //render Breadcrumbitems
  const _renderBreadcrumbItem: IRenderFunction<IBreadcrumbItem> = (item: any, defaultRender) => {
    let currentItemIndex = itemsWithHeading.indexOf(item);
    let isStepItemReached = currentItemIndex <= selectedStatusIndex;
    return (
      <Stack className={"breadcrumbitem"}>
        <Label className={isStepItemReached ? "visitedstep" : "step"}>{itemsWithHeading.indexOf(item) + 1}</Label>
        <Label className={isStepItemReached ? "statuscurrent" : "status"}>{item.text}</Label>
      </Stack>);
  }

  /**
   * render Breadcrumb Divider on change of steps
   * @param dividerProps 
   * @returns 
   */
  const renderBreadcrumbDivider = (dividerProps: any) => {
    const currentItemIndex = itemsWithHeading.indexOf(dividerProps.item);
    const separatorClassName = currentItemIndex < selectedStatusIndex;
    return (<div className={separatorClassName ? "separatorvisited" : "separator"}></div>);
  }

  return (
    <div>
      <Stack enableScopedSelectors horizontal tokens={stackTokens}>
        <Stack.Item grow={2} styles={inogiclogoStyle}>
          <img src={InogicLogo} alt="Logo" className='InogicLogo' />
        </Stack.Item>
        <Stack.Item grow={3} styles={inogiclogoStyle}>
          <img src={productDetails.productPath} alt="ProductImg" className='productImg' />
          {/* <Text className='headertext'>Follow the below instructions to complete the purchase process.</Text> */}
        </Stack.Item>
      </Stack>
      <Stack className='stepindicator' horizontalAlign="center">
        <Breadcrumb
          dividerAs={renderBreadcrumbDivider}
          items={itemsWithHeading}
          onRenderItem={_renderBreadcrumbItem}
        />
      </Stack>
      <div><StepContentComponent /></div>
    </div>
  );
};

export default StepIndicator
import { IStackItemStyles, Label, Stack, Text } from '@fluentui/react'
import React, { CSSProperties, FC, useEffect, useState } from 'react'
import companyData from '../assets/CompanyDetails.json'
import Loader from './Loader/Loader';
import { useLocation } from 'react-router-dom';
import Payment_succ_img from '../Images/Payment_Succesfull.png';
import PaymentFailedImg from '../Images/Payment_Failed.png'

const stackItemStyles: IStackItemStyles = {
  root: {
    alignItems: 'start',
    display: 'flex',
    width: '0%',
    justifyContent: 'start'
  },
};


const itemStyles: CSSProperties = {
  alignItems: 'left',
  display: 'flex',
  justifyContent: 'left',
  padding: '1em'
};
const labelStyles: CSSProperties = {
  fontSize: '28px',
  fontWeight: 'Bold'
}
const itemValues: CSSProperties = {
  fontWeight: '500'
}

const Activate: FC<any> = (props) => {

  const productDisplayName = props.productDetails.productDisplayName
  const productActivateDocsUrl = props.productDetails.activateLicUrl
  const [loading, setLoading] = useState(true)
  const [isTransactionSucceeded, setTransactionStatus] = useState<boolean>(false)

  const [paymentDetails, setPaymentDetails] = useState<any>();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  const order = queryParams.get('token');

  useEffect(() => {
    if (order !== undefined) {
      capturePayment(order)
    }
  }, [])


  const capturePayment = async (orderId: any) => {
    const functionName: string = "capturePayment";
    try {


      const clientId = companyData.clientId
      const clientSecret = companyData.clientSecret
      const tokenUrl = companyData.tokenUrl

      const headers = {
        'Authorization': 'Basic ' + btoa(clientId + ':' + clientSecret),
        'Content-Type': 'application/x-www-form-urlencoded'
      };

      const body = new URLSearchParams();
      body.append('grant_type', 'client_credentials');

      try {
        // Obtain access token
        // Read data from query string 

        const tokenResponse = await fetch(tokenUrl, {
          method: 'POST',
          headers,
          body: body.toString()
        });
        if (tokenResponse.ok) {
          const tokenData = await tokenResponse.json();
          const accessToken = tokenData.access_token;
          //const response = await fetch(`https://api-m.sandbox.paypal.com/v2/checkout/orders/${orderId}/capture`, {
          const response = await fetch(`https://api-m.paypal.com/v2/checkout/orders/${orderId}/capture`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
              'PayPal-Request-Id': 'fc1793bb-59e1-4914-8cd1-3548a123bf62',
            },
          });
          if (response.ok) {
            const data = await response.json();
            setTransactionStatus(data.status == "COMPLETED")
            createPaymentRecord(data)
          } else {
            alert('Error capturing PayPal order:' + response.statusText);
            console.error('Error capturing PayPal order:' + response.status, response.statusText);
          }
        } else {
          alert('Error obtaining access token:' + tokenResponse.statusText)
          console.error('Error obtaining access token:', tokenResponse.statusText);
        }
      } catch (error) {
        console.error('Error:', error);
        alert(functionName + "error" + error);
      }
    }
    catch (error: any) {
      alert(functionName + " : " + error)
      console.log(functionName + " : " + error)
    }
  }

  const createPaymentRecord = (responseData: any) => {
    const functioName: string = "createPayment";
    let orgDetails: any;
    let queryValue: any;
    let capturePayment = null
    try {
      const searchParams = new URLSearchParams(location.search);
      if (searchParams !== undefined && searchParams !== null) {
        queryValue = searchParams.get('userDetails');
        orgDetails = JSON.parse(queryValue);
        if (responseData.purchase_units[0].payments.captures[0] !== undefined) {
          capturePayment = responseData.purchase_units[0].payments.captures[0];

          let paymentDetails = {
            'product': isValid(orgDetails.productDetails.product) ? orgDetails.productDetails.product : "",
            'server': isValid(orgDetails.productDetails.orgName) ? orgDetails.productDetails.orgName : "",
            'orgname': isValid(orgDetails.productDetails.orgId) ? orgDetails.productDetails.orgId : "",
            'usercount': isValid(orgDetails.productDetails.noOfUsers) ? orgDetails.productDetails.noOfUsers : "",
            'customerRecordId': isValid(orgDetails.productDetails.customerRecordId) ? orgDetails.productDetails.customerRecordId : "",
            'partnerRecordId': isValid(orgDetails.productDetails.partnerRecordId) ? orgDetails.productDetails.partnerRecordId : "",
            'payerName': isValid(responseData.payer.name.given_name) ? responseData.payer.name.given_name : "",
            'txtId': isValid(capturePayment.id) ? capturePayment.id : "",
            'grossAmount': isValid(capturePayment.seller_receivable_breakdown.gross_amount.value) ? capturePayment.seller_receivable_breakdown.gross_amount.value : "",
            "netAmount": isValid(capturePayment.seller_receivable_breakdown.net_amount.value) ? capturePayment.seller_receivable_breakdown.net_amount.value : "",
            'paypal_fee': isValid(capturePayment.seller_receivable_breakdown.paypal_fee.value) ? capturePayment.seller_receivable_breakdown.paypal_fee.value : ""

          }

          let createPayment = companyData.createPaymenturl

          const proxyUrl = 'https://cors-anywhere.herokuapp.com';
          //@ts-ignore
          let encyptedValue = EncryptKey(JSON.stringify(paymentDetails))
          fetch(`${createPayment}?paymentDetails=${encodeURIComponent(encyptedValue)}`)
            .then(response => {
              response.text().then((textResult: any) => {
                if (textResult !== undefined) {
                  let licDetails = JSON.parse(textResult.replace('(', '').replace(')', '').replace(';', ''));
                  setPaymentDetails(paymentDetails)
                  setLoading(false)
                }
              });
            })
        }

      }
    } catch (error) {
      alert(functioName + ":" + error)
      console.error(functioName + ":" + error);
    }
  }
  return (<div>
    <Stack>
      <Stack className='form' >{loading ? (<><Loader></Loader></>) : (<>
        <Stack.Item className='formDetails' align='center' >
          {isTransactionSucceeded ? (<>
            <Stack className='txtResult' horizontal>
              <Stack.Item grow={1} styles={stackItemStyles}></Stack.Item>
              <Label style={labelStyles}>Transaction completed
                <img src={Payment_succ_img} alt="ProductImg" className='successImg' /> </Label>
            </Stack>
            <Stack enableScopedSelectors horizontal >
              <Stack.Item grow={1} styles={stackItemStyles}>
                <span style={itemStyles}><Text>Name :  <span style={itemValues}>{paymentDetails.payerName}</span> </Text></span>
              </Stack.Item>
              <Stack.Item grow={1} styles={stackItemStyles}>
                <span style={itemStyles}><Text>Amount : <span style={itemValues}>USD {paymentDetails.grossAmount}</span> </Text></span>
              </Stack.Item>
            </Stack>
            <Stack enableScopedSelectors horizontal >
              <Stack.Item grow={1} styles={stackItemStyles}>
                <span style={itemStyles}><Text>Transaction ID : <span style={itemValues}>{paymentDetails.txtId}</span></Text></span>
              </Stack.Item>
              <Stack.Item grow={1} styles={stackItemStyles}>
                <span style={itemStyles}><Text>Product Name : <span style={itemValues}>{productDisplayName}</span></Text></span>
              </Stack.Item>
            </Stack>
            <div>
              <p>Your transaction is successfully completed, kindly click on the <span style={itemValues}>Activate</span> button on License registration page of {productDisplayName}. For further details refer <a href={productActivateDocsUrl} target="_blank">here.</a></p>
            </div></>) : (<>
              <Stack className='txtResult' horizontal>
                <Stack.Item grow={1} styles={stackItemStyles}></Stack.Item>
                <Label style={labelStyles}>Transaction Failed
                  <img src={PaymentFailedImg} alt="ProductImg" className='successImg' /> </Label>
              </Stack>
              <div>
                <p>Payment of USD {paymentDetails.grossAmount} was unsuccessfull for your subscription, please update your payment information. For any other queries you can reach out to Inogic at crm@inogic.com</p>
              </div>
            </>)}
        </Stack.Item></>)}
      </Stack >
    </Stack>
  </div>)


  //This function validates the fields and their attributes
  function isValid(attributes: any) {
    var functionName = "isValid";
    try {
      if (attributes !== null && attributes !== "undefined" && attributes !== "") {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e, functionName);
    }
  }
}
export default Activate
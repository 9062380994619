import { Text, DefaultButton, Label, PrimaryButton, Stack, IStackItemStyles, addYears, Modal, FontWeights, mergeStyleSets, getTheme, IconButton, IButtonStyles, IIconProps } from '@fluentui/react';
import React, { CSSProperties, FC, useEffect, useState } from 'react'
import Loader from './Loader/Loader';
import CompanyDetails from '../assets/CompanyDetails.json'
import { useLocation } from 'react-router-dom';
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const theme = getTheme();
const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        theme.fonts.xxLarge,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    heading: {
        color: theme.palette.neutralPrimary,
        fontWeight: FontWeights.semibold,
        fontSize: 'inherit',
        margin: '0',
    },
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

const stackItemStyles: IStackItemStyles = {
    root: {
        alignItems: 'start',
        display: 'flex',
        height: 50,
        width: 40,
        justifyContent: 'start'
    },
};
const itemStyles: CSSProperties = {
    alignItems: 'left',
    display: 'flex',
    justifyContent: 'left',
};

const itemValues: CSSProperties = {
    fontWeight: '500'
}

const labelStyles: CSSProperties = {
    fontSize: '28px',
    fontWeight: 'Bold',
    marginBottom: "0.2em"
}

export interface IuserDetails {
    orgId: string;
    orgName: string;
    noOfUsers: number;
    price: string;
    product: string;
    tableInfo: string;
}


/**
 * this components is use to get price details based on user and product
 * @param param0 
 * @returns 
 */
const OrderSummary: FC<any> = (props) => {
    const [isLoading, setLoading] = useState(true)
    const productDisplayName = props.productDetails.productDisplayName
    const [errorMessage, setErrorMessage] = useState<any>("")
    const [currentDate, setCurrentDate] = useState<string>('');
    const [oneYearFromNow, setOneYearFromNow] = useState<string>('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    /**
     * on click of close window
     */
    const closeWindow = () => {
        window.close();
    }
    /**
     * on click of Next button send data to parent Component and change the steps
     */
    const changeStep = () => {
        //userDetails.price = priceDetails.price
        props.onDataSend(priceDetails)
    }
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search)

    //Use State to set the price details
    const [priceDetails, setPriceDetail] = useState<IuserDetails>({
        orgId: "",
        orgName: "",
        noOfUsers: 0,
        price: "",
        product: "",
        tableInfo: "",
    })

    useEffect(() => {

        const today = new Date();
        const currentDay = String(today.getDate()).padStart(2, '0');
        const currentMonth = String(today.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are 0-indexed
        const currentYear = today.getFullYear();
        setCurrentDate(`${currentDay}-${currentMonth}-${currentYear}`);

        const oneYearLater = new Date();
        oneYearLater.setFullYear(currentYear + 1);
        const oneYearLaterDay = String(oneYearLater.getDate() - 1).padStart(2, '0');
        const oneYearLaterMonth = String(oneYearLater.getMonth() + 1).padStart(2, '0');
        const oneYearLaterYear = oneYearLater.getFullYear();
        setOneYearFromNow(`${oneYearLaterDay}-${oneYearLaterMonth}-${oneYearLaterYear}`);

        if (isValid(searchParams)) {
            let queryValue = searchParams.get('value');

            //@ts-ignore
            let decryptedValue = InoEncryptDecrypt.EncryptDecrypt.decryptKey(queryValue)
            const orgDetails = JSON.parse(decryptedValue.DecryptedValue)

            //get price details 
            getProductPrice(orgDetails)
        }
    }, []);
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <div>{!isLoading ? (<>
            <Modal
                isOpen={isModalOpen}
                onDismiss={toggleModal} // This function will be called when you close the modal
                isBlocking={false} // Set to true if you want the modal to be blocking
            >
                {/* Modal content */}
                <div className={contentStyles.header}>
                    <h2 className={contentStyles.heading} >
                        Pricing Details
                    </h2>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={toggleModal}
                    />
                </div>
                <div className={contentStyles.body}>
                    <Label>Pricing for {productDisplayName} for {priceDetails.noOfUsers} user(s) will be USD {priceDetails.price} per annum for your organization </Label>
                    <Label>as per pricing bracket below.</Label>
                    <div dangerouslySetInnerHTML={{ __html: priceDetails.tableInfo }} />
                </div>
            </Modal>
            <Stack className='form'>
                <Stack.Item className='formDetails' align='center'>{!errorMessage ? (<>
                    <Label style={labelStyles}>Subscription Details</Label>
                    <Stack enableScopedSelectors horizontal >
                        <Stack.Item grow={1} styles={stackItemStyles}>
                            <span style={itemStyles}><Text>Product : <span style={itemValues}>{productDisplayName}</span> </Text></span>
                        </Stack.Item>
                        <Stack.Item grow={1} styles={stackItemStyles}>
                            <span style={itemStyles}><Text>Tenure : <span style={itemValues}>12 Months</span></Text></span>
                        </Stack.Item>
                    </Stack>
                    <Stack enableScopedSelectors horizontal >
                        <Stack.Item grow={1} styles={stackItemStyles}>
                            <span style={itemStyles}> <Text>User License : <span style={itemValues}>{priceDetails.noOfUsers}</span></Text></span>
                        </Stack.Item>
                        <Stack.Item grow={1} styles={stackItemStyles}>
                            <span style={itemStyles}><Text>Start Date : <span style={itemValues}>{currentDate} </span></Text></span>
                        </Stack.Item>
                    </Stack>
                    <Stack enableScopedSelectors horizontal >
                        <Stack.Item grow={1} styles={stackItemStyles}>
                            <span style={itemStyles}> <Text onClick={toggleModal}>Amount : <span style={itemValues}>USD {priceDetails.price}</span></Text></span>
                        </Stack.Item>
                        <Stack.Item grow={1} styles={stackItemStyles}>
                            <span style={itemStyles}><Text>End Date : <span style={itemValues}>{oneYearFromNow}</span></Text></span>
                        </Stack.Item>
                    </Stack>
                    <span style={itemStyles}><Text>The annual subscription cost of {productDisplayName} for your organization would be <span style={itemValues}> USD {priceDetails.price}</span>. <a onClick={toggleModal} style={{ display: "inline-flex", color: "blue", textDecoration: "underline", cursor: "pointer" }}> Know More</a></Text></span>
                    <Stack enableScopedSelectors horizontal className='consentButton'>
                        <DefaultButton onClick={closeWindow}>Cancel</DefaultButton>
                        <PrimaryButton onClick={changeStep}>Next</PrimaryButton>
                    </Stack></>) : (<><Text>{errorMessage}</Text></>)}
                </Stack.Item>
            </Stack >
        </>) : (
            <><Loader loadingMessage="Loading..." className="Spinner">Loading</Loader></>)}
        </div>
    );


    /**
 * check and validate license is valid for purchase
 * @param data 
 * @returns 
 */
    async function getProductPrice(orgDetails: any) {
        const functionName = "getProductPrice";

        try {
            // Getting URL from JSON
            if (CompanyDetails.GetPriceUrl !== undefined) {
                const apiUrl = CompanyDetails.GetPriceUrl;

                // Set request data
                const requestData = {
                    firstname: "Sam",
                    lastname: "Sam",
                    companyname: "",
                    emailaddress: "",
                    contactnumber: "",
                    product: orgDetails.product,
                    country: "",
                    estimateduser: parseInt(orgDetails.usercount),
                    isNewLead: "false",
                    hearaboutus: "",
                    hearAboutOthers: "",
                };

                const response = await fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(requestData),
                });

                if (response.ok) {
                    response.json().then((responseData: any) => {
                        const priceDetails = {
                            orgId: orgDetails.orgname,
                            orgName: orgDetails.server,
                            noOfUsers: orgDetails.usercount,
                            product: orgDetails.product,
                            price: responseData.price,
                            tableInfo: responseData.tableInfo,
                            productDisplayName: productDisplayName,
                        };
                        setPriceDetail(priceDetails);
                        setLoading(false);
                    });


                } else {
                    const errorData = await response.text();
                    setLoading(false);
                    setErrorMessage(functionName + errorData);
                    console.error("Request failed:", errorData);
                }
            }
        } catch (error) {
            setLoading(false);
            setErrorMessage(functionName + error);
            console.error(functionName + ":" + error);
        }
    }

    //This function validates the fields and their attributes
    function isValid(attributes: any) {
        var functionName = "isValid";
        try {
            if (attributes !== null && attributes !== "undefined" && attributes !== "" && attributes !== undefined) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            console.error(e, functionName);
        }
    }
}

export default OrderSummary;
import { Checkbox, DefaultButton, IStackItemStyles, Label, PrimaryButton, Stack, TextField } from '@fluentui/react'
import React, { CSSProperties, FC, useState } from 'react'
import companyDetails from '../assets/CompanyDetails.json'
import Loader from './Loader/Loader';
import { error } from 'console';

const stackTokens = { childrenGap: 10 };
const stackItemStyles: IStackItemStyles = {
    root: {
        alignItems: 'start',
        display: 'flex',
        height: 50,
        width: 40,
        justifyContent: 'start'
    },
};

const billingStyles: CSSProperties = {
    fontSize: '20px',
    fontWeight: '500',
    marginBottom: '1.1em',

}

const shippingStyle: CSSProperties = {
    fontSize: '20px',
    fontWeight: '500',
    marginBottom: '-0.4em',
}

/**
 * Billing  information page
 * @param props 
 * @returns 
 */
const BillingInfo: FC<any> = (props) => {
    const [errorMessage, setErrorMessage] = useState()
    const [isBiilingasshipping, setisBiilingasShipping] = useState<boolean>(false)
    const [isLoading, setLoader] = useState<boolean>(false)

    const [billingDetails, setBillingDetails] = useState({
        "companyName": "",
        "firstName": "",
        "lastName": "",
        "email": "",
        "street1": "",
        "street2": "",
        "street3": "",
        "city": "",
        "state": "",
        "zipCode": "",
        "country": ""
    })
    const [shippingDetails, setshippingDetails] = useState({
        "companyName": "",
        "firstName": "",
        "lastName": "",
        "email": "",
        "street1": "",
        "street2": "",
        "street3": "",
        "city": "",
        "state": "",
        "zipCode": "",
        "country": ""
    })

    /**
     * Handle values of shipping details on change
     * @param event 
     */
    const handleBillingInputChange = (event: any) => {
        try {
            const { name, value } = event.target;
            setBillingDetails({
                ...billingDetails,
                [name]: value,
            })
        }
        catch (error) {
            console.log(error)
        }
    }
    /**
     * Handle values of shipping details on change
     * @param event 
     */
    const handleShippingDetails = (event: any) => {
        try {
            const { name, value } = event.target;
            setshippingDetails({
                ...shippingDetails,
                [name]: value,
            })
        }
        catch (error) {
            console.log(error)
        }
    }

    /**
     * Attributes validation
     * @param attributes 
     * @returns 
     */
    function isValid(attributes: any) {
        var functionName = "isValid";
        try {
            if (attributes !== null && attributes !== "undefined" && attributes !== "" && attributes !== undefined) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            console.error(e, functionName);
        }
    }
    const validateEmail = (email: string) => {
        // Regular expression to validate email addresses
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        return emailPattern.test(email);
    };

    /**
     * on click on next create data in the crm and get ids
     */
    const handleOnclickOfnext = () => {
        let functionName: string = "handleOnclickOfnext";
        try {
            if (isValid(billingDetails.companyName) && isValid(billingDetails.firstName) && isValid(billingDetails.lastName) && isValid(billingDetails.email) && isValid(shippingDetails.companyName) && isValid(shippingDetails.firstName) && isValid(shippingDetails.lastName) && isValid(shippingDetails.email)) {

                setLoader(true);
                let customerDetails = {

                    "SD_companyName": shippingDetails.companyName,
                    "SD_firstName": shippingDetails.firstName,
                    "SD_lastName": shippingDetails.lastName,
                    "SD_email": shippingDetails.email,
                    "SD_street1": shippingDetails.street1,
                    "SD_street2": shippingDetails.street2,
                    "SD_street3": shippingDetails.street3,
                    "SD_city": shippingDetails.city,
                    "SD_state": shippingDetails.state,
                    "SD_zipCode": shippingDetails.zipCode,
                    "SD_country": shippingDetails.country,
                    "BD_companyName": billingDetails.companyName,
                    "BD_firstName": billingDetails.firstName,
                    "BD_lastName": billingDetails.lastName,
                    "BD_email": billingDetails.email,
                    "BD_street1": billingDetails.street1,
                    "BD_street2": billingDetails.street2,
                    "BD_street3": billingDetails.street3,
                    "BD_city": billingDetails.city,
                    "BD_state": billingDetails.state,
                    "BD_zipCode": billingDetails.zipCode,
                    "BD_country": billingDetails.country,
                    "isShippingAndBillingSame": isBiilingasshipping
                }
                //const proxyUrl = 'https://cors-anywhere.herokuapp.com';
                let createCustomerDetailsUrl = companyDetails.createCustomerRecordUrl
                //@ts-ignore
                let encyptedValue = EncryptKey(JSON.stringify(customerDetails))
                fetch(`${createCustomerDetailsUrl}?customerDetails=${encodeURIComponent(encyptedValue)}`)
                    .then((response: any) => {
                        response.text().then((textResult: any) => {
                            if (textResult !== undefined) {
                                let recordIds = JSON.parse(textResult.replace('(', '').replace(')', '').replace(';', ''));
                                props.productDetails.customerRecordId = recordIds.customerRecordId;
                                props.productDetails.partnerRecordId = recordIds.partnerRecordId;
                                
                                props.onDataSend(props)
                            }
                        });
                    }).catch((error: any) => {
                        console.log(functionName + ":" + error)
                    })
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    /**
     * on click of close window
     */
    const closeWindow = () => {
        window.close();
    }
    /**
     * Handle if user change shipping is same as billing chekbox
     * @param ev 
     * @param isChecked 
     */
    const handleSameAsBilling = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
        if (isChecked) {
            setshippingDetails({ ...billingDetails });
            setisBiilingasShipping(isChecked)
        }
        else {
            setisBiilingasShipping(false)
        }
    }
    return (
        <Stack className='form'>
            {isLoading ? (<><Loader loadingMessage="Loading..." className="Spinner"></Loader></>) : (<>
                <Stack.Item className='formDetails' align='center' style={{ height: '600px' }}>
                    <form>
                        {errorMessage ? (<>
                            <Label>{errorMessage}</Label></>) : (<>
                                <Stack horizontal >
                                    <Stack.Item grow={1} styles={stackItemStyles}>
                                        <Stack tokens={stackTokens} >
                                            <Label style={billingStyles}>Billing Details</Label>
                                            <TextField name='companyName' placeholder="Company Name" id='companyName' onChange={handleBillingInputChange} required />
                                            <Label>Contact Person</Label>
                                            <Stack horizontal tokens={stackTokens}>
                                                <TextField name='firstName' style={{ width: "120px" }} placeholder="First Name" onChange={handleBillingInputChange} required />
                                                <TextField name='lastName' style={{ width: "120px" }} placeholder="Last Name" onChange={handleBillingInputChange} required />
                                            </Stack>
                                            <TextField name='email' placeholder='Email' onChange={handleBillingInputChange} type='email' required></TextField>
                                            <Label>Billing Address</Label>
                                            <TextField name='street1' placeholder="Street 1" onChange={handleBillingInputChange} required />
                                            <TextField name='street2' placeholder="Street 2" onChange={handleBillingInputChange} required />
                                            <TextField name='street3' placeholder="Street 3" onChange={handleBillingInputChange} required />
                                            <TextField name='city' placeholder="City" onChange={handleBillingInputChange} required />
                                            <TextField name='state' placeholder="State" onChange={handleBillingInputChange} required />
                                            <TextField name='zipCode' placeholder="Zip Code" onChange={handleBillingInputChange} type='integer' required />
                                            <TextField name="country" placeholder="Country" onChange={handleBillingInputChange} required />
                                        </Stack>
                                    </Stack.Item>
                                    <Stack.Item grow={1} styles={stackItemStyles}>
                                        <Stack tokens={stackTokens} >
                                            <Label style={shippingStyle}>Shipping Details</Label>
                                            <Checkbox label='Same as billing details' onChange={handleSameAsBilling} />
                                            <TextField name='companyName' placeholder="Company Name" value={shippingDetails.companyName} id='companyName' onChange={handleShippingDetails} readOnly={isBiilingasshipping} required />
                                            <Label>Contact Person</Label>
                                            <Stack horizontal tokens={stackTokens}>
                                                <TextField name='firstName' style={{ width: "120px" }} placeholder="First Name" value={shippingDetails.firstName} onChange={handleShippingDetails} readOnly={isBiilingasshipping} required />
                                                <TextField name='lastName' style={{ width: "120px" }} placeholder="Last Name" value={shippingDetails.lastName} onChange={handleShippingDetails} readOnly={isBiilingasshipping} required />
                                            </Stack>
                                            <TextField name='email' placeholder="Email" value={shippingDetails.email} onChange={handleShippingDetails} readOnly={isBiilingasshipping} required ></TextField>
                                            <Label>Shipping Address</Label>
                                            <TextField name='street1' placeholder="Street 1" value={shippingDetails.street1} onChange={handleShippingDetails} readOnly={isBiilingasshipping} required />
                                            <TextField name='street2' placeholder="Street 2" value={shippingDetails.street2} onChange={handleShippingDetails} readOnly={isBiilingasshipping} required />
                                            <TextField name='street3' placeholder="Street 3" value={shippingDetails.street3} onChange={handleShippingDetails} readOnly={isBiilingasshipping} required />
                                            <TextField name='city' placeholder="City" value={shippingDetails.city} onChange={handleShippingDetails} readOnly={isBiilingasshipping} required />
                                            <TextField name='state' placeholder="State" value={shippingDetails.state} onChange={handleShippingDetails} readOnly={isBiilingasshipping} required />
                                            <TextField name='zipCode' placeholder="Zip Code" value={shippingDetails.zipCode} onChange={handleShippingDetails} readOnly={isBiilingasshipping} required />
                                            <TextField name="country" placeholder="Country" value={shippingDetails.country} onChange={handleShippingDetails} readOnly={isBiilingasshipping} required />
                                            <Stack.Item align='center'>
                                                <DefaultButton onClick={closeWindow} >Cancel</DefaultButton>
                                                <PrimaryButton type='submit' onClick={handleOnclickOfnext}>Next</PrimaryButton>
                                            </Stack.Item>
                                        </Stack>
                                    </Stack.Item>
                                </Stack>
                            </>)}

                    </form>
                </Stack.Item></>)}
        </Stack>
    )
}
export default BillingInfo
import * as React from "react";
import { Spinner, SpinnerSize } from "@fluentui/react";
export class Loader extends React.Component<any, any> {
  render() {
    return (
      <div id="loader">
        <Spinner label={this.props.loadingMessage} size={SpinnerSize.large} />
      </div>
    );
  }
}
export default Loader;

import { Checkbox, DefaultButton, Label, PrimaryButton, Stack, StackItem } from "@fluentui/react";
import React, { FC, useEffect, useState } from "react";
import Loader from "./Loader/Loader";
import CompanyDetails from '../assets/CompanyDetails.json'
import { useLocation } from "react-router-dom";

//close the window on click of cancel
const closeWindow = () => {
    window.close();
}

interface DataObject {
    orgId: string;
    orgName: string;
    noOfUsers: number;
}

let licenseDetails: any = {};

const ConfirmSubscription: FC<any> = (props: any) => {

    const productInfo = props.productDetails
    //To set/remove loader
    const [isLoading, setIsLoading] = useState(true)

    //Show details/message after license validation  
    const [isValidLicense, setLicenseDetails] = useState(false)

    //Set button enable/disable on change of consent
    const [isBoxChecked, setChecked] = useState<boolean>(false)

    //set error message
    const [errorMessage, setErrorMessage] = useState<any>(false)

    // Read data from query string 
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)

    useEffect(() => {
        if (queryParams !== undefined) {
            let queryValue = queryParams.get('value');

            //@ts-ignore
            let decryptedValue = InoEncryptDecrypt.EncryptDecrypt.decryptKey(queryValue)
            licenseDetails = JSON.parse(decryptedValue.DecryptedValue)

            //check License Details
            checkLicenseDetails(queryValue)
        }
    }, [])


    // Change stage on click of next and pass the data to the parent component
    const nextStage = () => {
        try {
            const data: DataObject = {
                orgId: licenseDetails.orgId,
                orgName: licenseDetails.crmUrl,
                noOfUsers: licenseDetails.usercount,
            };
            props.onDataSend(data);
        }
        catch (error: any) {
            console.error("nextStage :" + error.message)
        }
    };

    //on change of consent checkbox
    const onConsentChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
        setChecked(!isBoxChecked)
    }

    return (
        <div>{!isLoading ? (<>
            <Stack className='form'>{isValidLicense ? (<>
                <Stack.Item className='formDetails' align="center">
                    <div>
                        <div>
                            <Label className='userDetails'>You are purchasing the annual subscription of {productInfo.productDisplayName} for your Organization with below details.</Label>
                        </div>
                        <div>
                            <ul>
                                <li><Label className='userDetails'>Organization Unique Name : {licenseDetails.orgname}</Label></li>
                                <li><Label className='userDetails'>Organization URL : {licenseDetails.server}</Label></li>
                                <li><Label className='userDetails'>Product Name : {productInfo.productDisplayName
                                }</Label></li>
                                <li><Label className='userDetails'>User Count : {licenseDetails.activeUserCount}</Label></li>
                            </ul>
                        </div>
                        <div className='consent'>
                            <Checkbox onChange={onConsentChange} />
                            <Label className='consentMessage'>By selecting this, I understand and accept the <a href={productInfo.productEula} target="_blank">Terms and Conditions.</a></Label>
                        </div>
                        <Stack.Item className="consentButton">
                            <DefaultButton onClick={closeWindow}>Cancel</DefaultButton>
                            <PrimaryButton disabled={!isBoxChecked} onClick={nextStage}>Next</PrimaryButton>
                        </Stack.Item>
                    </div>
                </Stack.Item></>) : (<>
                    <StackItem align="center">{errorMessage ? (<>{errorMessage}</>) : (<>
                        You already have a subscription for this product, for further assistence contact to <b>crm@inogic.com</b>
                    </>
                    )}
                    </StackItem></>)}
            </Stack></>) : (
            <><Loader loadingMessage="Loading..." className="Spinner">Loading</Loader></>)}
        </div>
    )

    /**
     * read data from Inogic CRM and check if data is valid to purchase the product
     * @returns 
     */
    async function checkLicenseDetails(queryValue: any) {
        let functionName: string = "checkLicenseDetails";
        try {

            //const proxyUrl = 'https://cors-anywhere.herokuapp.com';
            //Read data from Url
            if (isValid(queryValue)) {
                const activateLicense = CompanyDetails.activateLicense;
                const urlWithQueryString = `${activateLicense}?details=${encodeURIComponent(queryValue)}`;
                //Fetch license details from server
                fetch(urlWithQueryString)
                    .then((response) => {
                        if (response.ok) {
                            return response.text();
                        } else {
                            setIsLoading(false);
                            setErrorMessage(`${functionName} : ${response.statusText}`);
                            console.error(`${functionName} : ${response.statusText}`);
                        }
                    })
                    .then((data: any) => {
                        // Check if the response data is valid
                        if (isValid(data)) {
                            const licDetails = JSON.parse(
                                data.replace('(', '').replace(')', '').replace(';', '')
                            );

                            // Check if the license is valid to purchase
                            const isLicValidToPurchase = validateLicenseDetails(licDetails);
                            // Set license details
                            setLicenseDetails(isLicValidToPurchase);
                        }
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        setErrorMessage(`${functionName} : ${error.message}`);
                        console.error(`${functionName} : ${error.message}`);
                    });
            }
        } catch (e: any) {
            setIsLoading(false)
            setErrorMessage(functionName + " : " + e.message)
            console.error(functionName + " : ", e.message);
        }
    }
    /**
     * validate the license to check if lic is valid to purchase the product
     * @param licData 
     * @returns 
     */
    function validateLicenseDetails(licData: any): any {
        let functionName: string = "validateLicenseDetails";
        let licenseDetailsObj: { [key: string]: string } = {};
        let isLicValidToPurchase: any;
        try {
            //get data from licensekey
            if (isValid(licData.LicenseKey)) {
                const splitValues = licData.LicenseKey.split('$$');
                splitValues.forEach((pair: any) => {
                    const [key, value] = pair.split('||');
                    licenseDetailsObj[key] = value;
                });

                isLicValidToPurchase = licenseDetailsObj.LicenseTo === "1";

            }
            else {
                setIsLoading(false)
                setErrorMessage(functionName + " : license value is undefined/null")
                console.error(functionName + " : license value is undefined/null");
            }
        }
        catch (e) {
            setIsLoading(false)
            setErrorMessage(functionName + e)
            console.error(e, functionName);
        }
        return isLicValidToPurchase
    }

    //This function validates the fields and their attributes
    function isValid(attributes: any) {
        var functionName = "isValid";
        try {
            if (attributes !== null && attributes !== "undefined" && attributes !== "") {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            setErrorMessage(functionName + " " + e)
            console.error(e, functionName);
        }
    }
}
export default ConfirmSubscription